exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-about-index-md": () => import("./../../../content/pages/about/index.md" /* webpackChunkName: "component---content-pages-about-index-md" */),
  "component---content-pages-about-mcp-transcript-md": () => import("./../../../content/pages/about/mcp-transcript.md" /* webpackChunkName: "component---content-pages-about-mcp-transcript-md" */),
  "component---content-pages-about-pgp-public-key-md": () => import("./../../../content/pages/about/pgp-public-key.md" /* webpackChunkName: "component---content-pages-about-pgp-public-key-md" */),
  "component---content-pages-apps-bristol-pound-index-md": () => import("./../../../content/pages/apps/bristol-pound/index.md" /* webpackChunkName: "component---content-pages-apps-bristol-pound-index-md" */),
  "component---content-pages-apps-ctt-objectos-index-md": () => import("./../../../content/pages/apps/ctt-objectos/index.md" /* webpackChunkName: "component---content-pages-apps-ctt-objectos-index-md" */),
  "component---content-pages-apps-fon-auto-login-index-md": () => import("./../../../content/pages/apps/fon-auto-login/index.md" /* webpackChunkName: "component---content-pages-apps-fon-auto-login-index-md" */),
  "component---content-pages-apps-gdrive-index-md": () => import("./../../../content/pages/apps/gdrive/index.md" /* webpackChunkName: "component---content-pages-apps-gdrive-index-md" */),
  "component---content-pages-apps-geosense-index-md": () => import("./../../../content/pages/apps/geosense/index.md" /* webpackChunkName: "component---content-pages-apps-geosense-index-md" */),
  "component---content-pages-apps-geosense-privacy-policy-md": () => import("./../../../content/pages/apps/geosense/privacy-policy.md" /* webpackChunkName: "component---content-pages-apps-geosense-privacy-policy-md" */),
  "component---content-pages-apps-index-md": () => import("./../../../content/pages/apps/index.md" /* webpackChunkName: "component---content-pages-apps-index-md" */),
  "component---content-pages-apps-o-meu-salario-index-md": () => import("./../../../content/pages/apps/o-meu-salario/index.md" /* webpackChunkName: "component---content-pages-apps-o-meu-salario-index-md" */),
  "component---content-pages-apps-quadratic-solver-index-md": () => import("./../../../content/pages/apps/quadratic-solver/index.md" /* webpackChunkName: "component---content-pages-apps-quadratic-solver-index-md" */),
  "component---content-pages-apps-the-bro-code-index-md": () => import("./../../../content/pages/apps/the-bro-code/index.md" /* webpackChunkName: "component---content-pages-apps-the-bro-code-index-md" */),
  "component---content-pages-apps-the-other-side-index-md": () => import("./../../../content/pages/apps/the-other-side/index.md" /* webpackChunkName: "component---content-pages-apps-the-other-side-index-md" */),
  "component---content-pages-apps-totojogos-index-md": () => import("./../../../content/pages/apps/totojogos/index.md" /* webpackChunkName: "component---content-pages-apps-totojogos-index-md" */),
  "component---content-pages-feeds-index-md": () => import("./../../../content/pages/feeds/index.md" /* webpackChunkName: "component---content-pages-feeds-index-md" */),
  "component---content-pages-windows-phone-index-md": () => import("./../../../content/pages/windows-phone/index.md" /* webpackChunkName: "component---content-pages-windows-phone-index-md" */),
  "component---content-pages-windows-phone-nokia-lumia-800-skin-md": () => import("./../../../content/pages/windows-phone/nokia-lumia-800-skin.md" /* webpackChunkName: "component---content-pages-windows-phone-nokia-lumia-800-skin-md" */),
  "component---content-pages-windows-phone-nokia-lumia-920-skin-md": () => import("./../../../content/pages/windows-phone/nokia-lumia-920-skin.md" /* webpackChunkName: "component---content-pages-windows-phone-nokia-lumia-920-skin-md" */),
  "component---content-pages-windows-phone-windows-phone-8-application-bar-icons-md": () => import("./../../../content/pages/windows-phone/windows-phone-8-application-bar-icons.md" /* webpackChunkName: "component---content-pages-windows-phone-windows-phone-8-application-bar-icons-md" */),
  "component---content-pages-windows-phone-windows-phone-application-bar-icons-md": () => import("./../../../content/pages/windows-phone/windows-phone-application-bar-icons.md" /* webpackChunkName: "component---content-pages-windows-phone-windows-phone-application-bar-icons-md" */),
  "component---src-components-category-archive-layout-tsx": () => import("./../../../src/components/categoryArchiveLayout.tsx" /* webpackChunkName: "component---src-components-category-archive-layout-tsx" */),
  "component---src-components-index-layout-index-layout-tsx": () => import("./../../../src/components/indexLayout/indexLayout.tsx" /* webpackChunkName: "component---src-components-index-layout-index-layout-tsx" */),
  "component---src-components-post-layout-post-layout-tsx": () => import("./../../../src/components/postLayout/postLayout.tsx" /* webpackChunkName: "component---src-components-post-layout-post-layout-tsx" */),
  "component---src-components-tag-archive-layout-tsx": () => import("./../../../src/components/tagArchiveLayout.tsx" /* webpackChunkName: "component---src-components-tag-archive-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

